@use "../settings/all" as *;
@use "../mixins/all" as *;
/* Global helper css classes */
.mobile-hidden {
  @include mobile-screen {
    display: none !important;
  }
}

.mobile-visible {
  display: none !important;
  @include mobile-screen {
    display: block !important;
  }
}

.hidden {
  display: none;
}

.hideTextareaStyle {
  background: none;
  border: none;
}

.no-padding {
  padding: 0 !important;
}

.move-out {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}
