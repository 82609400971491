@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.video-landingpage {
  position: absolute;
  top: -115px;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  background: url(../img/bgvideoimg.jpg) center no-repeat scroll;
  background-size: cover;
}

@include mobile-screen {
  .video-landingpage {
    left: unset;
    right: -115px;
  }
}

@media only screen and (max-width: 1091px) {
  .video-landingpage {
    top: 0%
  }
}

@media only screen and (min-width: 1092px) {
  .video-landingpage {
    height: 100%;

    @supports (object-fit: cover) {
      height: calc(100% + 115px); // minus hire teachers bar
    }

    object-fit: cover;
    overflow: hidden;
  }
}
