@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.footer {
  background-color: $dark-blue;
  color: #fff;
  display: flex;
  justify-content: center;

  &__container {
    max-width: 1440px;
    width: 100%;
  }

  a {
    @include on-focus {
      text-decoration: none;
    }
  }

  &__phone {
    text-decoration: underline;
  }

  &__upper {
    .row {
      margin: 0;

      & > div {
        margin-bottom: 12px;
        padding: 0;
      }
    }

    padding: 50px 140px;

    @include sm-screen {
      padding: 20px 15px;
    }

    a {
      color: $white;
    }
  }

  &__middle {
    background: $dark-blue;

    a {
      color: $white;
      font-size: var(--s-1);
      opacity: 0.7;

      @include on-focus {
        opacity: 1;
      }
    }

    &__list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-grow: 1;
      gap: var(--s-2);
    }
  }

  &__bottom {
    padding: 20px 40px;
    text-align: center;
  }

  &__list {
    .page-list .switcher & {
      flex-direction: row;
      --threshold: 20rem;
      row-gap: var(--s-2);
    }

    font-weight: 500;
    font-size: var(--s0);

    .contact-info .fa-fw {
      width: 22px;
    }

    i {
      padding-right: 0.3em;
    }

    .stack {
      --space: var(--s-2);
    }
  }

  .list-inline {
    padding: 1.2em 0 0 5px;

    li {
      padding: 0;
      margin-top: 5px;
    }
  }

  .rating {
    float: right;
  }

  &__img {
    max-width: 200px;
  }

  &__section-title {
    font-size: var(--s1);
    margin-bottom: 16px;
    color: #fff;
    font-family: $header-font;

    &--bold {
      font-weight: bold;
    }
  }

  &__rating {
    * {
      font-size: var(--s1);
    }
  }

  .form-control {
    background: transparent;
    color: white;
    box-shadow: none;

    &:focus {
      border-color: white;
    }
  }

  .control-label {
    padding-left: 0;
  }

  .form-group {
    margin-bottom: 10px;

    .error {
      color: $red;
    }
  }
}

@media (max-width: $xs-screen) {
  .footer {

    &__section {
      padding-left: 0;
      padding-right: 0;
    }

    &__list {
      margin: 0;

      i {
        padding-right: 0;
      }

      li {
        margin-bottom: 8px;
      }
    }

    &__social, &__img-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &__social {
      .list-inline {
        display: flex;
        padding: 1.2em 0 0;
      }
    }

    .rating {
      float: none;
    }

    .footer-headline {
      margin-bottom: 8px;
    }

    &__bottom {
      padding: 20px 15px;
      text-align: left;
    }
  }
}

@media (max-width: $tablet-vertical) {
  .footer {
    &__img {
      width: 100%;
    }
  }
}
