@use "../../settings/all" as *;
@use "../../mixins/all" as *;
@include large-screen {
  .main-content{
    margin: 50px 0 0;
    padding:0 6% 0 30%;

    .search-result {
      margin: 20px 0 !important;
    }
  }
}

@include not-large-screen {
  .main-content{
    padding: 10px;
    margin:12px;
  }
}
